<template>
  <div class="minha-conta" style="height:100vh">
    <header class="bg-dark py-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <img src="/img/logo.svg" width="150" height="34" />
            </router-link>
            <span class="badge badge-success ml-2">admin</span>
          </div>

          <div class="col text-right">
            <SideNav />
          </div>
        </div>
      </div>
    </header>
    <div class="container">
      <div class="bg-gradient">
        <div class="text-center my-5">
          <h3>Indicações</h3>
          <div class="h-line" style="width:142px;"></div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-4 mb-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                CPF indicador
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              v-mask="'###.###.###-##'"
              v-model="cpf"
            />
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                Nome cliente
              </span>
            </div>
            <input type="text" class="form-control" v-model="nome" />
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                Celular cliente
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              v-mask="['(##) ####-####', '(##) #####-####']"
              v-model="telefone"
            />
          </div>
        </div>

        <div class="col-md-4 mb-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                Data início
              </span>
            </div>
            <input
              type="tel"
              class="form-control"
              v-model="dataInicio"
              v-mask="'##/##/####'"
            />
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                Data fim
              </span>
            </div>
            <input
              type="tel"
              class="form-control"
              v-model="dataFim"
              v-mask="'##/##/####'"
            />
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <b-form-checkbox id="checkbox-1" v-model="contato" name="checkbox-1">
            <strong>Buscar quem não teve contato</strong>
          </b-form-checkbox>
        </div>
        <div class="col-md-4 mb-2">
          <button
            type="submit"
            class="btn btn-success btn-block mb-3"
            @click.prevent="obterIndicacoes()"
            :disabled="loading"
          >
            <span v-if="!loading">Buscar</span>
            <span v-if="loading">Por favor aguarde...</span>
          </button>
        </div>
      </div>
      <!-- Nossa Table -->
    </div>
    <div v-if="items.length==0" class="text-center">
      <strong>Nenhum registro encontrato para a busca informada!</strong>
    </div>

    <div v-else style="padding-bottom:20px" class="mx-5">
      <div class="text-right">
        <button
          type="submit"
          class="btn btn-success  mb-3"
          @click.prevent="exportarExcel()"
          :disabled="loading"
        >
          <span v-if="!loading">Exportar excel</span>
          <span v-if="loading">Por favor aguarde...</span>
        </button>
      </div>
      <div class="overflow-auto box-one">
        <b-table
          id="my-table"
          hover
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          small
        >
          <template #cell(id)="data">
            <b-button @click.prevent="abrirModal(data)" variant="success"
              >+</b-button
            >
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          pills
          aria-controls="my-table"
          align="center"
        ></b-pagination>
      </div>
    </div>
    <Loading :criandoSeguro="true" v-if="buscarDados" />
    <ModalIndicacaoMgm
      :nomeVendedorBR="vendedor"
      :editar="editar"
      :id="id"
      :showModal="showModal"
      @hide="hideModal"
    />
  </div>
</template>

<script>
import SideNav from "../../components/Admin/SideNav";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../config";
import moment from "moment";
import ModalIndicacaoMgm from "../../components/Admin/ModalIndicacaoMgm";
import Loading from "../../components/Usuario/Loading.vue";
export default {
  components: {
    Loading,
    ModalIndicacaoMgm,
    SideNav,
  },
  data() {
    return {
      config,
      showModal: false,
      buscarDados: false,
      loading: false,
      perPage: 20,
      dataFim: undefined,
      dataInicio: undefined,
      contato: true,
      cpf: "",
      nome: "",
      telefone: "",
      fields: [
        {
          key: "data",
          label: "Data",
        },
        {
          key: "nomeIndicado",
          label: "Nome cliente",
          sortable: true,
        },
        {
          key: "celularIndicado",
          label: "Celular cliente",
          formatter: (value) => {
            if (value.length === 11) {
              return `${"(" + value.substring(0, 2) + ")"} ${value.substring(
                2,
                3
              )} ${value.substring(3, 7)}-${value.substring(7)}`;
            }
          },
        },
        {
          key: "marca",
          label: "Marca bike",
        },
        {
          key: "marca",
          label: "Marca bike",
        },
        {
          key: "nomeLogado",
          label: "Nome indicador",
          sortable: true,
        },
        {
          key: "cpfLogado",
          label: "Cpf indicador",
          formatter: (value) => {
            return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
          },
        },
        {
          key: "celularLogado",
          label: "Celular indicador",
          formatter: (value) => {
            if (value.length === 11) {
              return `${"(" + value.substring(0, 2) + ")"} ${value.substring(
                2,
                3
              )} ${value.substring(3, 7)}-${value.substring(7)}`;
            }
          },
        },
        {
          key: "dataPrimeiroContato",
          label: "Primeiro contato",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return value;
            } else {
              return "não";
            }
          },
        },
        {
          key: "id",
          label: "Ação",
        },
      ],
      currentPage: 1,
      items: [],
      id: undefined,
      editar: true,
      vendedor: "",
    };
  },
  mounted() {
    this.obterIndicacoes();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    abrirModal(value) {
      this.id = value.item.id;
      this.editar = !value.item.primeiroContato;
      this.vendedor = value.item.nomeVendedorBR;
      this.showModal = true;
    },
    hideModal(value) {
      this.showModal = false;
      if (value.modificou) {
        this.obterIndicacoes();
      }
    },
    async exportarExcel() {
      try {
        if (
          (this.dataInicio && !this.dataFim) ||
          (!this.dataInicio && this.dataFim)
        ) {
          Swal.fire({
            title: "Ops...",
            text: "As datas precisam ser completas com inicio e fim.",
            icon: "info",
            confirmButtonText: "Ok",
          });
          return;
        }
        this.buscarDados = true;
        const dataInicio = this.dataInicio
          ? moment(this.dataInicio, "DD/MM/YYYY").format("YYYY-MM-DD")
          : undefined;
        const dataFim = this.dataFim
          ? moment(this.dataFim, "DD/MM/YYYY").format("YYYY-MM-DD")
          : undefined;

        const data = {
          cpfLogado: this.cpf,
          celularIndicado: this.telefone,
          nomeIndicado: this.nome,
          contato: this.contato,
          dataInicio,
          dataFim,
        };
        var response = await axios.post(
          `${config.API_URLV2}/mgmTransacaoIndicacao/buscarMgmIndicacaoExcel`,
          data,
          {
            responseType: "blob", // Importante para tratar o retorno como blob
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "output.xlsx"); // Nome do arquivo que será baixado
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.buscarDados = false;
        return response.data;
      } catch (error) {
        this.buscarDados = false;
        Swal.fire({
          title: "Não foi possível buscar os indicados",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    async obterIndicacoes() {
      try {
        if (
          (this.dataInicio && !this.dataFim) ||
          (!this.dataInicio && this.dataFim)
        ) {
          Swal.fire({
            title: "Ops...",
            text: "As datas precisam ser completas com inicio e fim.",
            icon: "info",
            confirmButtonText: "Ok",
          });
          return;
        }
        this.buscarDados = true;
        const dataInicio = this.dataInicio
          ? moment(this.dataInicio, "DD/MM/YYYY").format("YYYY-MM-DD")
          : undefined;
        const dataFim = this.dataFim
          ? moment(this.dataFim, "DD/MM/YYYY").format("YYYY-MM-DD")
          : undefined;

        const data = {
          cpfLogado: this.cpf,
          celularIndicado: this.telefone,
          nomeIndicado: this.nome,
          contato: this.contato,
          dataInicio,
          dataFim,
        };
        var response = await axios.post(
          `${config.API_URLV2}/mgmTransacaoIndicacao/buscarMgmIndicacao`,
          data
        );
        this.items = response.data;
        this.buscarDados = false;
      } catch (error) {
        this.buscarDados = false;
        Swal.fire({
          title: "Não foi possível buscar os indicados",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.minha-conta {
  font-family: "Montserrat";
  background-color: #f4f3ef;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
</style>
