<template>
  <b-modal
    :visible="showModal"
    :title="
      !editar
        ? 'Informações do contato'
        : 'Adicionar iformações do primeiro contato'
    "
    :hide-footer="true"
    @hide="hideModal"
    size="lg"
  >
    <div class="row">
      <div v-if="!editar" class="col-lg-12 order-lg-2">
        Nome do vendedor: {{ nomeVendedorBR }}
      </div>
      <div v-else class="col-lg-12 order-lg-2">
        <ValidationObserver v-slot="{ handleSubmit, invalid }">
          <form class="mt-2" @submit.prevent="handleSubmit(salvar)">
            <div class="form-group">
              <ValidationProvider
                name="nome"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <label>Nome vendedor</label>
                <input
                  v-model="nome"
                  type="text"
                  class="form-control"
                  :disabled="!editar"
                  :class="classes"
                  placeholder="Nome do vendedor"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <ValidationProvider
                    name="data"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <label>Data do contato</label>
                    <b-form-input
                      :class="classes"
                      :disabled="!editar"
                      v-model="data"
                      type="date"
                    ></b-form-input>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <ValidationProvider
                    name="data"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <label>Hora do contato</label>
                    <b-form-input
                      :class="classes"
                      v-model="time"
                      :disabled="!editar"
                      type="time"
                    ></b-form-input>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <button
                  type="submit"
                  class="btn btn-success btn-block"
                  :disabled="invalid || loadingText"
                >
                  <span v-if="!loadingText">Salvar</span>
                  <span v-else>Por favor aguarde...</span>
                </button>
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  class="btn btn-danger btn-block"
                  @click="hideModal"
                  :disabled="loadingText"
                >
                  <span>Cancelar</span>
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import captureError from "../../helpers/captureError";
import formatacaoData from "../../helpers/formatacaoData";
import moment from "moment";
export default {
  props: ["showModal", "id", "editar", "nomeVendedorBR"],
  data() {
    return {
      historico: [],
      config,
      formatacaoData,
      loading: false,
      feedBack: "",
      loadingText: false,
      data: undefined,
      time: undefined,
      nome: "",
    };
  },
  watch: {
    showModal: function(value) {
      if (!value) {
        this.data = undefined;
        this.nome = "";
        this.time = undefined;
      }
    },
  },
  methods: {
    hideModal(value) {
      this.$emit("hide", {
        modificou: value != true ? false : value,
        id: this.id,
      });
    },

    async salvar() {
      this.loadingText = true;
      try {
        const valor = this.data.toString() + " " + this.time.toString();
        const datafim = valor ? moment(valor).format() : undefined;
        var data = {
          id: this.id,
          nomeVendedorBR: this.nome,
          dataPrimeiroContato: datafim,
        };
        await axios.put(
          `${config.API_URLV2}/mgmTransacaoIndicacao/atualizarMgmIndicacao`,
          data
        );
        Swal.fire("Sucesso!", "A indicação foi atualizada com sucesso");
        this.hideModal(true);
        this.loadingText = false;
      } catch (error) {
        this.loadingText = false;
        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível atualizar a indicação. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
